@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 Bootstrap
 *========================================================================== */

.container {
	max-width: 1200px;
	position: relative; }

.row-gutters-5 {
  margin-right: -5px;
  margin-left: -5px;
  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; } }

.btn-group-lg > .btn, .btn-lg {
	padding: 12px 20px; }

.navbar {
	@include font-size(20px);
	padding: 0; }

.navbar-nav {
	white-space: nowrap;
	.nav-item.active a.nav-link {
		color: $link-hover; } }

.navbar-expand .navbar-nav .nav-link {
	padding: 0 30px; }

.navbar-expand-md .navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row; }

.breadcrumb {
	@include font-size(16px);
	background-color: inherit;
	padding: 25px 0 30px;
	margin-bottom: 0;
	@include br(0);
	a {
		color: #BCBCBC;
		text-decoration: none;
		&:hover {
			color: $link-green;
			text-decoration: none; } }
	@include w(768) {
		display: none; } }

.breadcrumb-item.active {
	color: #BCBCBC; }

.breadcrumb-item + .breadcrumb-item {
	padding-left: 15px; }

.breadcrumb-item + .breadcrumb-item::before {
	content: "\f105";
	color: #BCBCBC;
	font: normal normal normal 18px/1.35 FontAwesome;
	padding-right: 15px; }

.pagination {
	display: block;
	text-align: center;
	margin-bottom: 0;
	.page-item {
		display: inline-block;
		margin: 0 10px; }
	.page-item.active {
		.page-link, .page-link:focus {
			color: $link-green;
			background: inherit;
			border: 0 !important;
			border-radius: 0 !important;
			box-shadow: none !important; } }
	.page-link {
		display: inline;
		color: #333333;
		padding: 0;
		border: 0 !important;
		border-radius: 0 !important;
		&:hover, &:focus {
			color: $link-green;
			background: inherit;
			border: 0 !important;
			border-radius: 0 !important; } } }

.card {
	border: 0;
	@include br(10px); }

.card-product {
	width: 100%;
	max-width: 270px;
	position: relative;
	text-align: center;
	padding-top: 20px;
	overflow: hidden;
	margin: 0 auto 30px;
	text-decoration: none !important;
	@include transition(all 300ms ease);
	@include br(10px);
	&:hover {
		background: $grey;
		.card-title, .card-volume {
			opacity: 0; }
		.btn {
			opacity: 1;
			bottom: 30px; } }
	img {
		display: block;
		margin: 0 auto;
		width: auto !important;
		max-width: none !important;
		height: auto;
		max-height: 210px; }
	.card-body {
		padding: 20px; }
	.card-title {
		@include font-size(20px);
		color: #333333;
		height: 26px;
		overflow: hidden;
		@include transition(all 100ms ease); }
	.card-volume {
		@include font-size(22px);
		color: $link-green;
		font-weight: 700;
		@include transition(all 100ms ease); }
	.btn {
		@include font-size(18px);
		width: 85%;
		max-width: 230px;
		position: absolute;
		left: 50%;
		bottom: -100px;
		opacity: 0;
		transform: translateX(-50%);
		@include transition(all 300ms ease); } }

.carousel-thumbs {
	display: flex;
	flex-direction: row;
	padding-top: 5px;
	margin-bottom: 30px;
	.carousel-inner {
		position: relative;
		flex: 0 0 78%;
		width: 78%; }
	.carousel-item {
		width: 100%;
		height: 500px;
		overflow: hidden;
		img {
			display: block;
			max-width: none;
			max-height: 100% !important;
			@include absolut(50%,auto,auto,50%);
			transform: translate(-50%, -50%); } }
	.carousel-indicators {
		flex-direction: column;
		flex: 0 0 22%;
		width: 22%;
		justify-content: flex-start;
		position: relative;
		left: 0;
		right: auto;
		margin: 0; }
	.carousel-indicators li {
		width: 100%;
		height: auto;
		text-indent: inherit;
		margin: 0;
		opacity: 1;
		border: 0; }
	.carousel-indicators li:not(:last-child) {
		margin-bottom: 10px; }
	.carousel-indicators li.active .item {
		background-color: #F8F8F8; }
	.carousel-indicators li .item {
		width: 100%;
		height: 110px;
		overflow: hidden;
		position: relative;
		@include br(10px); }
	.carousel-indicators li .item img {
		display: block;
		max-width: none;
		max-height: 80% !important;
		@include absolut(50%,auto,auto,50%);
		transform: translate(-50%, -50%); } }
