@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 Magnific Popup
 *========================================================================== */
.mfp-bg {
	background: #000000; }

.mfp-close {
	@include font-size(30px);
	display: none !important; }

.popup-close {
	padding: 20px;
	@include absolut(0,0,auto,auto);
	&:hover .svg {
		fill: $green; }
	.svg {
		width: 24px;
		height: 24px;
		margin-right: 0; } }

.mfp-popup {
	max-width: 480px;
	margin: 0 auto;
	padding: 20px;
	background: #ffffff;
	text-align: center;
	@include br(10px);
	.popup-header {
		min-height: 40px;
		padding-bottom: 10px; }
	.popup-footer {
		padding: 30px 0;
		border-top: 1px solid $border; }
	.title {
		@include font-size(20px);
		line-height: 32px;
		font-weight: 700;
		text-align: left;
		color: $link-green;
		margin-bottom: 0; }
	ul.navbar-nav {
		display: block;
		@include font-size(20px);
		margin-bottom: 20px;
		.nav-item.active a.nav-link {
			color: $link-green; }
		a.nav-link {
			color: #333333;
			padding: 10px 15px;
			&:hover {
				color: $link-green; } } }
	ul.list-header-contact {
		li {
			@include font-size(20px);
			font-weight: 400; }
		li a {
			color: #333333;
			text-decoration: none !important; }
		li a:hover {
			color: $link-green; }
		.svg {
			fill: $green;
			margin-right: 15px; } }
	ul#tabs-filter {
		li {
			position: relative; }
		li.active {
			.svg {
				transform: rotate(-180deg); } }
		.widget-title {
			@include font-size(20px);
			max-width: none;
			cursor: pointer;
			padding: 15px 0;
			margin-bottom: 0; }
		.svg {
			width: 18px;
			height: 18px;
			fill: #666666;
			margin-right: 0;
			@include absolut(20px,0,auto,auto); }
		ul.submenu {
			display: none;
			margin-top: 10px;
			margin-bottom: 20px; } } }

.mfp-zoom-in {
	.mfp-animate {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8); }
	&.mfp-bg {
		opacity: 0;
		transition: all 0.3s ease-out; }
	&.mfp-ready {
		.mfp-animate {
			opacity: 1;
			transform: scale(1); }
		&.mfp-bg {
			opacity: 0.5; } }
	&.mfp-removing {
		.mfp-animate {
			transform: scale(0.8);
			opacity: 0; }
		&.mfp-bg {
			opacity: 0; } } }
