@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 OwlCarousel
 *========================================================================== */
.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
	background: $green; }

.owl-carousel.owl-theme .owl-dots .owl-dot span {
	background: #F0F0F0; }

.owl-carousel.owl-theme .owl-nav {
	margin: 0;
	@include absolut(-70px,0,auto,auto);
	button.owl-prev {
		border-radius: 10px 0 0 10px; }
	button.owl-next {
		border-radius: 0 10px 10px 0; }
	.svg {
		width: 21px;
		height: 21px;
		fill: #C2C2C2;
		margin-top: -3px;
		margin-right: 0; } }

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
	width: 51px;
	height: 40px;
	background-color: #F0F0F0;
	margin: 0;
	@include transition(all 300ms ease);
	&:hover {
		background-color: #3F8E29;
		.svg {
			fill: #ffffff; } } }

.owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0px, 0px, 0px); }

#owl-product {
	.card-product {
		margin-bottom: 0;
		&:hover {
			background: $grey; }
		.card-title, .card-volume {
			opacity: 1; }
		.btn {
			display: none; } } }
