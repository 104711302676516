@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 Button
 *========================================================================== */

button:focus, button:active {
	box-shadow: none !important;
	outline-offset: 0 !important;
	outline: none !important;
	border: 0 !important; }

.btn {
	position: relative;
	display: inline-block;
	@include font-size(20px);
	line-height: 1.25 !important;
	padding: 12px 20px 14px !important;
	font-weight: bold;
	text-decoration: none !important;
	border: 0 !important;
	box-shadow: 0 !important;
	@include transition(all 300ms ease);
	@include br(10px);
	&:focus, &:active {
		box-shadow: none !important;
		outline-offset: 0 !important;
		outline: none !important;
		border: 0 !important; }
	@include w(992) {
		@include font-size(18px);
		padding: 12px 16px 14px !important; } }

.btn-media {
	@include w(575) {
		width: 100%;
		max-width: 280px; } }

.btn-light {
	color: $link-green !important;
	background: #ffffff !important;
	&:hover {
		color: $link-green !important; } }

.btn-success {
	color: #ffffff !important;
	background: $link-green !important;
	&:hover {
		color: #ffffff !important; } }

.btn-secondary {
	color: #333333 !important;
	background: $grey !important;
	&:hover {
		color: $link-green !important;
		background: $grey !important; } }

.btn-reset {
	@include font-size(18px);
	padding: 12px 0 0 !important;
	color: #999999;
	border-bottom: 1px dashed #999999 !important;
	@include br(0);
	&:hover {
		color: $link-green !important;
		border-bottom: 1px dashed $link-green !important; } }

.btn-menu-open {
	padding: 0 !important;
	border: 0;
	@include absolut(50%,15px,auto,auto);
	transform: translate(0, -50%);
	@include br(0); }

.btn-filter {
	margin-top: -15px;
	text-align: left;
	color: $link-green !important;
	.svg {
		margin-right: 0;
		@include absolut(50%,16px,auto,auto);
		transform: translate(0, -50%); } }

