/* ==========================================================================
 Reset
 *========================================================================== */
* {
	margin: 0;
	padding: 0; }

html {
  font-size: 100%;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-modal-wrap: 100%; }

body {
	font: 400 20px/1.35 "Calibri", Arial, sans-serif;
	background-color: #fff;
	color: #333;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-moz-osx-font-smoothing: grayscale;
	position: relative; }

ol, ul {
	margin: 0;
	padding: 0; }

li {
	list-style-type: none; }

img {
	max-width: 100%;
	height: auto !important; }

i, em {
	font-style: italic; }

b, strong {
	font-weight: 700; }

textarea {
	width: 100%;
	resize: vertical; }

button {
	border: 0;
	padding: 0;
	cursor: pointer;
	&:focus, &:active {
		outline: none !important;
		border: 0 !important; } }

button, input {
	margin: 0;
	padding: 0; }

*:focus,
*::active,
*::after:focus,
*::before:focus {
	outline-offset: 0 !important;
	outline: none !important;
	border: 0 !important; }

a:active, a:focus {
	outline: none; }

*:invalid {
	-webkit-box-shadow: none;
	box-shadow: none; }
