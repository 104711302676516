/* ==========================================================================
 Fonts
 *========================================================================== */
@font-face {
  font-family: "Calibri";
  src: url("../fonts/Calibri/calibri-regular/Calibri.eot");
  src: url("../fonts/Calibri/calibri-regular/Calibri.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri/calibri-regular/Calibri.woff") format("woff"), url("../fonts/Calibri/calibri-regular/Calibri.ttf") format("truetype"), url("../fonts/Calibri/calibri-regular/Calibri.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("../fonts/Calibri/calibri-bold/Calibri-Bold.eot");
  src: url("../fonts/Calibri/calibri-bold/Calibri-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri/calibri-bold/Calibri-Bold.woff") format("woff"), url("../fonts/Calibri/calibri-bold/Calibri-Bold.ttf") format("truetype"), url("../fonts/Calibri/calibri-bold/Calibri-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}

/* ==========================================================================
 Reset
 *========================================================================== */
* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-modal-wrap: 100%;
}

body {
  font: 400 20px/1.35 "Calibri", Arial, sans-serif;
  background-color: #fff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

ol, ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto !important;
}

i, em {
  font-style: italic;
}

b, strong {
  font-weight: 700;
}

textarea {
  width: 100%;
  resize: vertical;
}

button {
  border: 0;
  padding: 0;
  cursor: pointer;
}

button:focus, button:active {
  outline: none !important;
  border: 0 !important;
}

button, input {
  margin: 0;
  padding: 0;
}

*:focus,
*::active,
*::after:focus,
*::before:focus {
  outline-offset: 0 !important;
  outline: none !important;
  border: 0 !important;
}

a:active, a:focus {
  outline: none;
}

*:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* ==========================================================================
 Typography
 *========================================================================== */
h1, h2, h3, h4 {
  line-height: 1.3;
  font-weight: 700;
}

h1 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1.2;
}

h3 {
  font-size: 30px;
  font-size: 1.875rem;
}

h4 {
  font-size: 22px;
  font-size: 1.375rem;
}

dl, ol, p, ul {
  margin-bottom: 30px;
}

.hidden-30 {
  margin-bottom: -30px !important;
  overflow: hidden;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.display-1 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 1200px) {
  .display-1 {
    font-size: 45px;
    font-size: 2.8125rem;
  }
}

@media only screen and (max-width: 992px) {
  .display-1 {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .display-1 {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media only screen and (max-width: 575px) {
  .display-1 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.display-2 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 700;
}

@media only screen and (max-width: 992px) {
  .display-2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

.display-3 {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.3;
  font-weight: 700;
}

@media only screen and (max-width: 992px) {
  .display-3 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 575px) {
  .display-3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.display-4, .widget-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 700;
}

.display-5 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 700;
}

@media only screen and (max-width: 992px) {
  .display-5 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 768px) {
  .display-5 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.title {
  margin-bottom: 60px;
}

.title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
  margin-bottom: 0;
}

.editor {
  font-size: 22px;
  font-size: 1.375rem;
}

a {
  color: #3F8E29;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

a:hover, a:focus {
  color: #3F8E29;
  text-decoration: underline;
  box-shadow: none !important;
  outline-offset: 0 !important;
  outline: none !important;
}

.svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.svg.svg-phone {
  width: 18px;
  height: 18px;
  fill: #ffffff;
}

.svg.svg-mail {
  width: 20px;
  height: 20px;
  fill: #ffffff;
}

.svg.svg-menu {
  width: 30px;
  height: 30px;
  margin: 0;
  fill: #ffffff;
}

.svg.svg-close {
  width: 20px;
  height: 20px;
  fill: #DADADA;
}

.svg.svg-close:hover {
  fill: #408e29;
}

.svg.svg-correct {
  width: 50px;
  height: 50px;
  fill: #3F8E29;
}

.svg.svg-download {
  width: 18px;
  height: 18px;
  fill: #BCBCBC;
}

.svg.svg-up-arrow {
  width: 30px;
  height: 30px;
  fill: #333333;
}

.svg.svg-equalizer {
  width: 22px;
  height: 22px;
  fill: #3F8E29;
}

.svg.svg-prev {
  width: 21px;
  height: 21px;
  fill: #3F8E29;
}

.svg.svg-next {
  width: 21px;
  height: 21px;
  fill: #3F8E29;
}

/* ==========================================================================
 Block
 *========================================================================== */
section {
  padding: 80px 0;
}

.header.header-fixed {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  position: fixed;
  -webkit-animation-name: animationFade;
  -o-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #408e29 !important;
  padding: 15px 0;
  z-index: 99;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.header a {
  color: #ffffff;
}

.header a:hover, .header a:focus {
  text-decoration: none !important;
  color: #C0FFA9;
}

.header .container {
  padding-left: 15px;
  padding-right: 15px;
}

.header a.navbar-brand {
  margin-right: 30px;
}

.header a.navbar-brand img {
  max-width: 200px;
}

.header .svg.svg-menu {
  margin-right: 0;
  margin-bottom: 0;
}

a.navbar-brand {
  display: block;
  padding: 0;
}

a.navbar-brand img {
  max-width: 250px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

section.welcome {
  padding: 0;
  height: 100vh;
  background-image: url("../images/bg/welcome.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #408e29;
  background-size: cover;
}

section.welcome .container {
  height: 100%;
}

section.welcome .welcome-content {
  width: 100%;
  max-width: 400px;
  color: #fff;
  padding-top: 90px;
}

section.welcome .welcome-content h1 {
  margin-bottom: 35px;
}

section.welcome-image {
  padding: 0;
  height: 410px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

section.welcome-no-image {
  padding-top: 80px;
  padding-bottom: 0;
}

section.product {
  padding-top: 0;
}

section.contact {
  padding-top: 0;
}

section.source {
  background-image: url("../images/bg/source.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: #ffffff;
}

section.source .title {
  margin-bottom: 60px;
}

section.source .hidd {
  margin-bottom: -30px;
  overflow: hidden;
}

section.carousel {
  border-top: 1px solid #E1E1E1;
}

.flag-icons {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.flag-icons .icons-img {
  margin-bottom: 5px;
}

.greybox {
  padding: 30px 20px;
  color: #333333 !important;
  background: #f0f0f0;
  text-align: center;
  display: flex;
  justify-content: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.greybox-body {
  position: relative;
  width: 83%;
  text-align: left;
  min-height: 60px;
  padding-left: 70px;
  position: relative;
  font-size: 22px;
  font-size: 1.375rem;
}

.greybox-body .svg {
  width: 50px;
  height: 50px;
  margin-top: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
}

.greybox-body p {
  margin-bottom: 0;
}

.greybox-body-body {
  flex: 1;
}

.content-null {
  margin: 0 !important;
  padding: 0 !important;
}

.content-top {
  margin-top: -5px;
}

.content {
  margin-top: -5px;
}

.content dl, .content ol, .content p, .content ul {
  margin-bottom: 25px;
}

.content .editor {
  font-size: 18px;
  font-size: 1.125rem;
}

.content .greybox {
  background-color: #F8F8F8;
  padding: 15px 30px;
  margin-bottom: 25px;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  border-radius: 28px;
}

.content .greybox .greybox-body {
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  padding-left: 52px;
  min-height: 54px;
}

.content .greybox .greybox-body p {
  margin-bottom: 0;
}

.content .greybox .greybox-body .svg {
  width: 32px;
  height: 32px;
  margin-top: 10px;
}

.sidebar.sidebar-right {
  padding-left: 30px;
}

.widget-contact .widget-title {
  padding-bottom: 20px;
  border-bottom: 1px solid #E0E0E0;
}

.widget-filter .widget-title {
  max-width: 200px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E0E0E0;
}

.widget-filter ul.submenu {
  margin-top: 17px;
  margin-bottom: 30px;
}

.footer {
  position: relative;
  background: #f0f0f0;
  padding-top: 30px;
}

.footer-nav {
  width: 100%;
  padding: 0 0 30px;
}

.footer-nav a {
  color: #333333;
}

.footer-nav a:hover, .footer-nav a:focus {
  text-decoration: none !important;
  color: #3F8E29;
}

.footer-nav .navbar-nav .nav-item.active a.nav-link {
  color: #3F8E29;
}

.footer-nav .container {
  padding-left: 15px;
  padding-right: 15px;
}

.footer-nav .navbar {
  font-size: 18px;
  font-size: 1.125rem;
}

.footer-nav a.navbar-brand {
  margin-right: 30px;
}

.footer-nav a.navbar-brand img {
  max-width: 200px;
}

.footer-nav ul.list-header-contact li {
  font-weight: 400;
}

.footer-nav .svg {
  fill: #333333;
}

.footer-copy {
  font-size: 16px;
  font-size: 1rem;
  color: #999999;
  padding: 18px 0;
  border-top: 1px solid #DDDDDD;
}

.footer-copy p {
  margin-bottom: 0;
}

@-webkit-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-o-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.dmtop {
  z-index: 555;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #408e29;
  text-align: center;
  position: fixed;
  bottom: -100px;
  right: 30px;
  cursor: pointer;
  -webkit-transition: all 0.7s ease-in-out 0s;
  -o-transition: all 0.7s ease-in-out 0s;
  transition: all 0.7s ease-in-out 0s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.dmtop .svg {
  width: 15px;
  height: 15px;
  fill: #ffffff;
  margin-right: 0;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .dmtop {
    right: 15px;
  }
}

/* ==========================================================================
 Button
 *========================================================================== */
button:focus, button:active {
  box-shadow: none !important;
  outline-offset: 0 !important;
  outline: none !important;
  border: 0 !important;
}

.btn {
  position: relative;
  display: inline-block;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.25 !important;
  padding: 12px 20px 14px !important;
  font-weight: bold;
  text-decoration: none !important;
  border: 0 !important;
  box-shadow: 0 !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
  outline-offset: 0 !important;
  outline: none !important;
  border: 0 !important;
}

@media only screen and (max-width: 992px) {
  .btn {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 12px 16px 14px !important;
  }
}

@media only screen and (max-width: 575px) {
  .btn-media {
    width: 100%;
    max-width: 280px;
  }
}

.btn-light {
  color: #3F8E29 !important;
  background: #ffffff !important;
}

.btn-light:hover {
  color: #3F8E29 !important;
}

.btn-success {
  color: #ffffff !important;
  background: #3F8E29 !important;
}

.btn-success:hover {
  color: #ffffff !important;
}

.btn-secondary {
  color: #333333 !important;
  background: #f0f0f0 !important;
}

.btn-secondary:hover {
  color: #3F8E29 !important;
  background: #f0f0f0 !important;
}

.btn-reset {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 12px 0 0 !important;
  color: #999999;
  border-bottom: 1px dashed #999999 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.btn-reset:hover {
  color: #3F8E29 !important;
  border-bottom: 1px dashed #3F8E29 !important;
}

.btn-menu-open {
  padding: 0 !important;
  border: 0;
  position: absolute;
  top: 50%;
  left: auto;
  right: 15px;
  bottom: auto;
  transform: translate(0, -50%);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.btn-filter {
  margin-top: -15px;
  text-align: left;
  color: #3F8E29 !important;
}

.btn-filter .svg {
  margin-right: 0;
  position: absolute;
  top: 50%;
  left: auto;
  right: 16px;
  bottom: auto;
  transform: translate(0, -50%);
}

/* ==========================================================================
 Form
 *========================================================================== */
label.checkbox {
  padding: 8px 0;
  margin: 0;
}

label.checkbox input[type="checkbox"] {
  display: none;
}

label.checkbox input[type="checkbox"]:checked ~ span {
  color: #3F8E29;
}

label.checkbox input[type="checkbox"]:checked ~ span:before {
  content: "";
  border: 1px solid #72C45B;
}

label.checkbox input[type="checkbox"]:checked ~ span:after {
  background-color: #3F8E29;
}

label.checkbox span {
  position: relative;
  padding-left: 38px;
  font: 400 20px/1.35 "Calibri", Arial, sans-serif;
  color: #333333;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-bottom: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.checkbox span:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #E0E0E0;
  position: absolute;
  top: 5px;
  left: 0;
  right: auto;
  bottom: auto;
}

label.checkbox span:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  top: 8px;
  left: 3px;
  right: auto;
  bottom: auto;
}

.form-filter {
  margin-bottom: 20px;
}

.form-filter .btn {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
}

.form-filter .btn-reset {
  font-size: 18px;
  font-size: 1.125rem;
}

.form-filter ul {
  text-align: left;
}

/* ==========================================================================
 List
 *========================================================================== */
ul.list-header-contact {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-bottom: 0;
}

ul.list-header-contact li {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0;
}

ul.list-header-contact .svg {
  width: 15px;
  height: 15px;
  margin-right: 12px;
  margin-bottom: 3px;
}

ul.list-about {
  text-align: center;
  margin-bottom: 30px;
}

ul.list-about p {
  margin-bottom: 0;
}

ul.list-about li {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3F8E29;
  max-width: 300px;
  margin: 0 auto 30px;
  position: relative;
}

ul.list-about .icon {
  position: relative;
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #F3F3F3;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

ul.list-about .icon img {
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

ul.list-about .svg.svg-download {
  fill: #BCBCBC;
}

ul.list-about .title {
  display: block;
  margin-bottom: 10px;
}

ul.list-about .tabs-js {
  display: none;
}

ul.list-about ul.submenu {
  display: block;
}

ul.list-about ul.submenu li {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0;
}

ul.list-social {
  width: 100%;
  text-align: right;
  margin-bottom: 0;
}

ul.list-social li {
  display: inline-block;
  vertical-align: top;
}

ul.list-social li:not(:last-child) {
  margin-right: 10px;
}

ul.list-social a:hover .svg {
  fill: #3F8E29;
}

ul.list-social .svg {
  width: 30px;
  height: 30px;
  fill: #333333;
  margin: 0;
}

ul.list-detail {
  margin-bottom: 15px;
}

ul.list-detail .item {
  position: relative;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  padding: 15px 15px 17px 53px;
  margin-bottom: 10px;
  background-color: #F8F8F8;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  border-radius: 28px;
}

ul.list-detail img {
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 13px;
  left: 15px;
  right: auto;
  bottom: auto;
}

ul.list-attribute {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 0;
}

ul.list-attribute .attribute-label, ul.list-attribute .attribute-value {
  display: inline;
  vertical-align: top;
}

ul.list-attribute .attribute-label {
  font-weight: 700;
}

ul.list-contact {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 0;
}

ul.list-contact span.padd {
  padding-left: 31px;
  display: block;
}

ul.list-contact ul.submenu {
  margin: 20px 0 30px;
}

ul.list-contact ul.submenu li {
  position: relative;
  padding-left: 37px;
  padding-right: 10px;
  padding-bottom: 10px;
}

ul.list-contact li.active .svg.svg-download {
  transform: rotate(-180deg);
}

ul.list-contact .svg {
  width: 22px;
  height: 22px;
  fill: #3F8E29;
  position: absolute;
  top: 3px;
  left: 0;
  right: auto;
  bottom: auto;
}

ul.list-contact .tabs-js {
  position: relative;
}

ul.list-contact .svg.svg-download {
  width: 18px;
  height: 18px;
  fill: #666666;
  margin-right: 0;
  position: absolute;
  top: 20px;
  left: auto;
  right: 0;
  bottom: auto;
}

/* ==========================================================================
 Bootstrap
 *========================================================================== */
.container {
  max-width: 1200px;
  position: relative;
}

.row-gutters-5 {
  margin-right: -5px;
  margin-left: -5px;
}

.row-gutters-5 > .col,
.row-gutters-5 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 12px 20px;
}

.navbar {
  font-size: 20px;
  font-size: 1.25rem;
  padding: 0;
}

.navbar-nav {
  white-space: nowrap;
}

.navbar-nav .nav-item.active a.nav-link {
  color: #C0FFA9;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 0 30px;
}

.navbar-expand-md .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  background-color: inherit;
  padding: 25px 0 30px;
  margin-bottom: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.breadcrumb a {
  color: #BCBCBC;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: #3F8E29;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb-item.active {
  color: #BCBCBC;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f105";
  color: #BCBCBC;
  font: normal normal normal 18px/1.35 FontAwesome;
  padding-right: 15px;
}

.pagination {
  display: block;
  text-align: center;
  margin-bottom: 0;
}

.pagination .page-item {
  display: inline-block;
  margin: 0 10px;
}

.pagination .page-item.active .page-link, .pagination .page-item.active .page-link:focus {
  color: #3F8E29;
  background: inherit;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.pagination .page-link {
  display: inline;
  color: #333333;
  padding: 0;
  border: 0 !important;
  border-radius: 0 !important;
}

.pagination .page-link:hover, .pagination .page-link:focus {
  color: #3F8E29;
  background: inherit;
  border: 0 !important;
  border-radius: 0 !important;
}

.card {
  border: 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.card-product {
  width: 100%;
  max-width: 270px;
  position: relative;
  text-align: center;
  padding-top: 20px;
  overflow: hidden;
  margin: 0 auto 30px;
  text-decoration: none !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.card-product:hover {
  background: #f0f0f0;
}

.card-product:hover .card-title, .card-product:hover .card-volume {
  opacity: 0;
}

.card-product:hover .btn {
  opacity: 1;
  bottom: 30px;
}

.card-product img {
  display: block;
  margin: 0 auto;
  width: auto !important;
  max-width: none !important;
  height: auto;
  max-height: 210px;
}

.card-product .card-body {
  padding: 20px;
}

.card-product .card-title {
  font-size: 20px;
  font-size: 1.25rem;
  color: #333333;
  height: 26px;
  overflow: hidden;
  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;
}

.card-product .card-volume {
  font-size: 22px;
  font-size: 1.375rem;
  color: #3F8E29;
  font-weight: 700;
  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;
}

.card-product .btn {
  font-size: 18px;
  font-size: 1.125rem;
  width: 85%;
  max-width: 230px;
  position: absolute;
  left: 50%;
  bottom: -100px;
  opacity: 0;
  transform: translateX(-50%);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.carousel-thumbs {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  margin-bottom: 30px;
}

.carousel-thumbs .carousel-inner {
  position: relative;
  flex: 0 0 78%;
  width: 78%;
}

.carousel-thumbs .carousel-item {
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.carousel-thumbs .carousel-item img {
  display: block;
  max-width: none;
  max-height: 100% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.carousel-thumbs .carousel-indicators {
  flex-direction: column;
  flex: 0 0 22%;
  width: 22%;
  justify-content: flex-start;
  position: relative;
  left: 0;
  right: auto;
  margin: 0;
}

.carousel-thumbs .carousel-indicators li {
  width: 100%;
  height: auto;
  text-indent: inherit;
  margin: 0;
  opacity: 1;
  border: 0;
}

.carousel-thumbs .carousel-indicators li:not(:last-child) {
  margin-bottom: 10px;
}

.carousel-thumbs .carousel-indicators li.active .item {
  background-color: #F8F8F8;
}

.carousel-thumbs .carousel-indicators li .item {
  width: 100%;
  height: 110px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.carousel-thumbs .carousel-indicators li .item img {
  display: block;
  max-width: none;
  max-height: 80% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

/* ==========================================================================
 Magnific Popup
 *========================================================================== */
.mfp-bg {
  background: #000000;
}

.mfp-close {
  font-size: 30px;
  font-size: 1.875rem;
  display: none !important;
}

.popup-close {
  padding: 20px;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
}

.popup-close:hover .svg {
  fill: #408e29;
}

.popup-close .svg {
  width: 24px;
  height: 24px;
  margin-right: 0;
}

.mfp-popup {
  max-width: 480px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.mfp-popup .popup-header {
  min-height: 40px;
  padding-bottom: 10px;
}

.mfp-popup .popup-footer {
  padding: 30px 0;
  border-top: 1px solid #DDDDDD;
}

.mfp-popup .title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 32px;
  font-weight: 700;
  text-align: left;
  color: #3F8E29;
  margin-bottom: 0;
}

.mfp-popup ul.navbar-nav {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.mfp-popup ul.navbar-nav .nav-item.active a.nav-link {
  color: #3F8E29;
}

.mfp-popup ul.navbar-nav a.nav-link {
  color: #333333;
  padding: 10px 15px;
}

.mfp-popup ul.navbar-nav a.nav-link:hover {
  color: #3F8E29;
}

.mfp-popup ul.list-header-contact li {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
}

.mfp-popup ul.list-header-contact li a {
  color: #333333;
  text-decoration: none !important;
}

.mfp-popup ul.list-header-contact li a:hover {
  color: #3F8E29;
}

.mfp-popup ul.list-header-contact .svg {
  fill: #408e29;
  margin-right: 15px;
}

.mfp-popup ul#tabs-filter li {
  position: relative;
}

.mfp-popup ul#tabs-filter li.active .svg {
  transform: rotate(-180deg);
}

.mfp-popup ul#tabs-filter .widget-title {
  font-size: 20px;
  font-size: 1.25rem;
  max-width: none;
  cursor: pointer;
  padding: 15px 0;
  margin-bottom: 0;
}

.mfp-popup ul#tabs-filter .svg {
  width: 18px;
  height: 18px;
  fill: #666666;
  margin-right: 0;
  position: absolute;
  top: 20px;
  left: auto;
  right: 0;
  bottom: auto;
}

.mfp-popup ul#tabs-filter ul.submenu {
  display: none;
  margin-top: 10px;
  margin-bottom: 20px;
}

.mfp-zoom-in .mfp-animate {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-animate {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.5;
}

.mfp-zoom-in.mfp-removing .mfp-animate {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* ==========================================================================
 OwlCarousel
 *========================================================================== */
.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background: #408e29;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background: #F0F0F0;
}

.owl-carousel.owl-theme .owl-nav {
  margin: 0;
  position: absolute;
  top: -70px;
  left: auto;
  right: 0;
  bottom: auto;
}

.owl-carousel.owl-theme .owl-nav button.owl-prev {
  border-radius: 10px 0 0 10px;
}

.owl-carousel.owl-theme .owl-nav button.owl-next {
  border-radius: 0 10px 10px 0;
}

.owl-carousel.owl-theme .owl-nav .svg {
  width: 21px;
  height: 21px;
  fill: #C2C2C2;
  margin-top: -3px;
  margin-right: 0;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  width: 51px;
  height: 40px;
  background-color: #F0F0F0;
  margin: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #3F8E29;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover .svg {
  fill: #ffffff;
}

.owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

#owl-product .card-product {
  margin-bottom: 0;
}

#owl-product .card-product:hover {
  background: #f0f0f0;
}

#owl-product .card-product .card-title, #owl-product .card-product .card-volume {
  opacity: 1;
}

#owl-product .card-product .btn {
  display: none;
}
