@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 Form
 *========================================================================== */
label.checkbox {
	padding: 8px 0;
	margin: 0;
	input[type="checkbox"] {
		display: none; }
	input[type="checkbox"]:checked ~ span {
		color: $link-green; }
	input[type="checkbox"]:checked ~ span:before {
		content: "";
		border: 1px solid #72C45B; }
	input[type="checkbox"]:checked ~ span:after {
		background-color: $link-green; }
	span {
		position: relative;
		padding-left: 38px;
		font: 400 20px/1.35 "Calibri", Arial, sans-serif;
		color: #333333;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		margin-bottom: 0;
		@include unselectable;
		&:before {
			content: "";
			display: block;
			width: 18px;
			height: 18px;
			border: 1px solid #E0E0E0;
			@include absolut(5px,auto,auto,0); }
		&:after {
			content: "";
			width: 12px;
			height: 12px;
			background: #fff;
			@include absolut(8px,auto,auto,3px); } } }

.form-filter {
	margin-bottom: 20px;
	.btn {
		@include font-size(20px);
		font-weight: 400; }
	.btn-reset {
		@include font-size(18px); }
	ul {
		text-align: left; } }
