@import "../base/mixins/mixins";
@import "../base/colors";

/* ==========================================================================
 List
 *========================================================================== */
ul.list-header-contact {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin-bottom: 0;
	li {
		@include font-size(18px);
		font-weight: 700;
		margin-bottom: 0; }
	.svg {
		width: 15px;
		height: 15px;
		margin-right: 12px;
		margin-bottom: 3px; } }

ul.list-about {
	text-align: center;
	margin-bottom: 30px;
	p {
		margin-bottom: 0; }
	li {
		@include font-size(24px);
		font-weight: 700;
		color: $link-green;
		max-width: 300px;
		margin: 0 auto 30px;
		position: relative; }
	.icon {
		position: relative;
		width: 120px;
		height: 120px;
		line-height: 120px;
		background-color: #F3F3F3;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 10px;
		text-align: center;
		@include br(50%);
		img {
			display: block;
			width: 60px;
			height: 60px;
			@include absolut(50%,auto,auto,50%);
			transform: translate(-50%, -50%); } }
	.svg.svg-download {
		fill: #BCBCBC; }
	.title {
		display: block;
		margin-bottom: 10px; }
	.tabs-js {
		display: none; }
	ul.submenu {
		display: block;
		li {
			@include font-size(18px);
			line-height: 1.2;
			font-weight: 400;
			color: #333333;
			margin-bottom: 0; } } }

ul.list-social {
	width: 100%;
	text-align: right;
	margin-bottom: 0;
	li {
		display: inline-block;
		vertical-align: top; }
	li:not(:last-child) {
		margin-right: 10px; }
	a:hover .svg {
		fill: #3F8E29; }
	.svg {
		width: 30px;
		height: 30px;
		fill: #333333;
		margin: 0; } }

ul.list-detail {
	margin-bottom: 15px;
	.item {
		position: relative;
		@include font-size(18px);
		line-height: 1.2;
		padding: 15px 15px 17px 53px;
		margin-bottom: 10px;
		background-color: #F8F8F8;
		@include br(28px); }
	img {
		display: inline-block;
		width: 26px;
		height: 26px;
		@include absolut(13px,auto,auto,15px); } }

ul.list-attribute {
	@include font-size(18px);
	margin-bottom: 0;
	.attribute-label, .attribute-value {
		display: inline;
		vertical-align: top; }
	.attribute-label {
		font-weight: 700; } }

ul.list-contact {
	@include font-size(20px);
	margin-bottom: 0;
	span.padd {
		padding-left: 31px;
		display: block; }
	ul.submenu {
		margin: 20px 0 30px; }
	ul.submenu li {
		position: relative;
		padding-left: 37px;
		padding-right: 10px;
		padding-bottom: 10px; }
	li.active .svg.svg-download {
		transform: rotate(-180deg); }
	.svg {
		width: 22px;
		height: 22px;
		fill: #3F8E29;
		@include absolut(3px,auto,auto,0); }
	.tabs-js {
		position: relative; }
	.svg.svg-download {
		width: 18px;
		height: 18px;
		fill: #666666;
		margin-right: 0;
		@include absolut(20px,0,auto,auto); } }
