@import "../base/mixins/mixins";
@import "../base/colors";
/* ==========================================================================
 Block
 *========================================================================== */
section {
	padding: 80px 0; }

.header.header-fixed {
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	margin: 0;
	position: fixed;
	-webkit-animation-name: animationFade;
	-o-animation-name: animationFade;
	animation-name: animationFade;
	-webkit-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
	@include transition(all 300ms ease); }

.header {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: $green !important;
	padding: 15px 0;
	z-index: 99;
	@include transition(all 300ms ease);
	a {
		color: #ffffff;
		&:hover, &:focus {
			text-decoration: none !important;
			color: $link-hover; } }
	.container {
		padding-left: 15px;
		padding-right: 15px; }
	a.navbar-brand {
		margin-right: 30px;
		img {
			max-width: 200px; } }
	.svg.svg-menu {
		margin-right: 0;
		margin-bottom: 0; } }

a.navbar-brand {
	display: block;
	padding: 0;
	img {
		max-width: 250px;
		@include transition(all 300ms ease); } }

section.welcome {
	padding: 0;
	height: 100vh;
	@include jpg(bg/welcome,no-repeat,top,center);
	background-color: $green;
	background-size: cover;
	.container {
		height: 100%; }
	.welcome-content {
		width: 100%;
		max-width: 400px;
		color: #fff;
		padding-top: 90px;
		h1 {
			margin-bottom: 35px; } } }

section.welcome-image {
	padding: 0;
	height: 410px;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover; }

section.welcome-no-image {
	padding-top: 80px;
	padding-bottom: 0; }

section.product {
	padding-top: 0; }

section.contact {
	padding-top: 0; }

section.source {
	@include jpg(bg/source,no-repeat,top,center);
	background-size: cover;
	color: #ffffff;
	.title {
		margin-bottom: 60px; }
	.hidd {
		margin-bottom: -30px;
		overflow: hidden; } }

section.carousel {
	border-top: 1px solid #E1E1E1; }

.flag-icons {
	@include font-size(24px);
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;
	.icons-img {
		margin-bottom: 5px; } }

.greybox {
	padding: 30px 20px;
	color: #333333 !important;
	background: $grey;
	text-align: center;
	display: flex;
	justify-content: center;
	@include br(10px); }

.greybox-body {
	position: relative;
	width: 83%;
	text-align: left;
	min-height: 60px;
	padding-left: 70px;
	position: relative;
	@include font-size(22px);
	.svg {
		width: 50px;
		height: 50px;
		margin-top: 5px;
		@include absolut(0, auto, auto, 0); }
	p {
		margin-bottom: 0; }
	&-body {
		flex: 1; } }

.content-null {
	margin: 0 !important;
	padding: 0 !important; }

.content-top {
	margin-top: -5px; }

.content {
	margin-top: -5px;
	dl, ol, p, ul {
		margin-bottom: 25px; }
	.editor {
		@include font-size(18px); }
	.greybox {
		background-color: #F8F8F8;
		padding: 15px 30px;
		margin-bottom: 25px;
		@include br(28px); }
	.greybox .greybox-body {
		width: 100%;
		@include font-size(18px);
		padding-left: 52px;
		min-height: 54px;
		p {
			margin-bottom: 0; }
		.svg {
			width: 32px;
			height: 32px;
			margin-top: 10px; } } }

.sidebar.sidebar-right {
	padding-left: 30px; }

.widget-contact {
	.widget-title {
		padding-bottom: 20px;
		border-bottom: 1px solid #E0E0E0; } }

.widget-filter {
	.widget-title {
		max-width: 200px;
		padding-bottom: 20px;
		border-bottom: 1px solid #E0E0E0; }
	ul.submenu {
		margin-top: 17px;
		margin-bottom: 30px; } }

.footer {
	position: relative;
	background: $grey;
	padding-top: 30px; }

.footer-nav {
	width: 100%;
	padding: 0 0 30px;
	a {
		color: #333333;
		&:hover, &:focus {
			text-decoration: none !important;
			color: $link-green; } }
	.navbar-nav .nav-item.active a.nav-link {
		color: $link-green; }
	.container {
		padding-left: 15px;
		padding-right: 15px; }
	.navbar {
		@include font-size(18px); }
	a.navbar-brand {
		margin-right: 30px;
		img {
			max-width: 200px; } }
	ul.list-header-contact li {
		font-weight: 400; }
	.svg {
		fill: #333333; } }

.footer-copy {
	@include font-size(16px);
	color: #999999;
	padding: 18px 0;
	border-top: 1px solid #DDDDDD;
	p {
		margin-bottom: 0; } }

@-webkit-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0); }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none; } }

@-o-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0); }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none; } }

@keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0); }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none; } }




