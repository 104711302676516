@import "mixins/mixins";
@import "colors";
/* ==========================================================================
 Typography
 *========================================================================== */
h1, h2, h3, h4 {
	line-height: 1.3;
	font-weight: 700; }

h1 {
	@include font-size(40px);
	line-height: 1.2; }

h2 {
	@include font-size(34px);
	line-height: 1.2; }

h3 {
	@include font-size(30px); }

h4 {
	@include font-size(22px); }

dl, ol, p, ul {
	margin-bottom: 30px; }

.hidden-30 {
	margin-bottom: -30px !important;
	overflow: hidden; }

.mb-15 {
	margin-bottom: 15px !important; }

.mb-20 {
	margin-bottom: 20px !important; }

.mb-30 {
	margin-bottom: 30px !important; }

.mb-40 {
	margin-bottom: 40px !important; }

.mb-60 {
	margin-bottom: 60px !important; }

.pb-0 {
	padding-bottom: 0 !important; }

.display-1 {
	@include font-size(50px);
	line-height: 1.1;
	font-weight: 700;
	letter-spacing: 0.5px;
	@include w(1200) {
		@include font-size(45px); }
	@include w(992) {
		@include font-size(40px); }
	@include w(768) {
		@include font-size(35px); }
	@include w(575) {
		@include font-size(30px); } }

.display-2 {
	@include font-size(32px);
	line-height: 1.3;
	font-weight: 700;
	@include w(992) {
		@include font-size(28px); } }

.display-3 {
	@include font-size(38px);
	line-height: 1.3;
	font-weight: 700;
	@include w(992) {
		@include font-size(28px); }
	@include w(575) {
		@include font-size(24px); } }

.display-4, .widget-title {
	@include font-size(24px);
	line-height: 1.3;
	font-weight: 700; }

.display-5 {
	@include font-size(32px);
	line-height: 1.3;
	font-weight: 700;
	@include w(992) {
		@include font-size(28px); }
	@include w(768) {
		@include font-size(22px); } }

.title {
	margin-bottom: 60px;
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 0; } }

.editor {
	@include font-size(22px); }

a {
	color: $link-green;
	text-decoration: none;
	@include transition(all 300ms ease);
	&:hover, &:focus {
		color: $link-green;
		text-decoration: underline;
		box-shadow: none !important;
		outline-offset: 0 !important;
		outline: none !important; } }

.svg {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	@include transition(all 300ms ease);
	&.svg-phone {
		width: 18px;
		height: 18px;
		fill: #ffffff; }
	&.svg-mail {
		width: 20px;
		height: 20px;
		fill: #ffffff; }
	&.svg-menu {
		width: 30px;
		height: 30px;
		margin: 0;
		fill: #ffffff; }
	&.svg-close {
		width: 20px;
		height: 20px;
		fill: $light-grey;
		&:hover {
			fill: $green; } }
	&.svg-correct {
		width: 50px;
		height: 50px;
		fill: $link-green; }
	&.svg-download {
		width: 18px;
		height: 18px;
		fill: #BCBCBC; }
	&.svg-up-arrow {
		width: 30px;
		height: 30px;
		fill: #333333; }
	&.svg-equalizer {
		width: 22px;
		height: 22px;
		fill: $link-green; }
	&.svg-prev {
		width: 21px;
		height: 21px;
		fill: $link-green; }
	&.svg-next {
		width: 21px;
		height: 21px;
		fill: $link-green; } }
